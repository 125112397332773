/**
 * Obfuscate.jsx
 *
 * @file This component is used to obfuscate sensible data like e-mail addresses to prevent spam from bots.
 * Based on react-obfuscate {@link https://github.com/coston/react-obfuscate/blob/master/src/obfuscate.js}
 * @license MIT {@link https://github.com/coston/react-obfuscate/blob/master/LICENSE}
 */

import PropTypes from 'prop-types'
import React, { useState } from 'react'

/**
 * This component receives sensible data, which should be hidden for bots
 * and obfuscates it.
 *
 * @param {Object} props The component props.
 * @return {Node} The rendered and obfuscated component.
 */
const Obfuscate = ( {
	children,
	element,
	email,
	facetime,
	headers,
	href,
	linkText,
	obfuscate,
	obfuscateChildren,
	onClick,
	sms,
	style,
	tel,
	...rest
} ) => {

	/** Define a state to check if a human is interacting with this component. */
    const [ humanInteraction, setHumanInteraction ] = useState( false )

    const linkProps = children || tel || sms || facetime || email || href
    const Component = element

	/**
	 * Generate the link based on the data.
	 *
	 * @return {string} Generated `href` link.
	 */
    const generateLink = () => {

        let link

        // Combine email header parameters for use with email
		const combineHeaders = params =>
			Object.keys( params )
				.map( key => `${ key }=${ encodeURIComponent( params[ key ] ) }` )
				.join( '&' )

        if ( email ) {

            link = `mailto:${ email }`

            if ( headers ) link += `?${ combineHeaders( headers ) }`

        }
        else if ( tel ) link = `tel:${ tel }`

        else if ( sms ) link = `sms:${ sms }`

        else if ( facetime ) link = `facetime:${ facetime }`

        else if ( href ) link = href

        else if ( typeof children !== 'object' ) link = children

        else return ''

        return link

    }

	/** Callback to handle an received `onClick` event. */
    const handleClick = () => {

        // If focused or hovered, this js will be skipped with preference for html
        if ( humanInteraction === false ) {

            // Allow instantiator to provide an onClick method to be called
            // before we change location (e.g. for analytics tracking)
            if ( onClick && typeof onClick === 'function' ) onClick()

			if ( typeof window !== 'undefined' )

				window.location.href = generateLink( {
					children,
					email,
					facetime,
					headers,
					href,
					sms,
					tel
				} )

        }

    }

    const reverse = content =>
        typeof content !== 'undefined' &&
        content
            .split( '' )
            .reverse()
            .join( '' )
            .replace( '(', ')' )
            .replace( ')', '(' )
            .replace( '@', '[ta]' )

    const obfuscatedStyle = {
        ...style,
        unicodeBidi: 'bidi-override',
        direction:
            humanInteraction === true ||
            obfuscate === false ||
            obfuscateChildren === false
                ? 'ltr'
                : 'rtl'
    }

    const renderedLink =
        humanInteraction === true ||
        obfuscate === false ||
        typeof children === 'object' ||
        obfuscateChildren === false // Allow child elements
            ? linkProps
            : reverse( linkProps )

    const clickProps =
        Component === 'a'
            ? {
                href:
                    humanInteraction === true || obfuscate === false
                    ? generateLink()
                    : linkText || 'obfuscated',
                onClick: handleClick
            }
        : {}

    return (
        <Component
            onFocus={ () => setHumanInteraction( true ) }
            onMouseOver={ () => setHumanInteraction( true ) }
            onContextMenu={ () => setHumanInteraction( true ) }
            { ...rest }
            { ...clickProps }
            style={ obfuscatedStyle }>
            { renderedLink }
        </Component>
    )

}

Obfuscate.defaultProps = {
    children: undefined,
    element: 'a',
    email: undefined,
    facetime: undefined,
    headers: undefined,
    href: undefined,
    linkText: undefined,
    obfuscate: undefined,
    obfuscateChildren: undefined,
    onClick: undefined,
    sms: undefined,
    style: {},
    tel: undefined
}

/**
 * 
 */
Obfuscate.propTypes = {
    children: PropTypes.node,
    element: PropTypes.string,
    email: PropTypes.string,
    facetime: PropTypes.string,
    headers: PropTypes.shape( {} ),
    href: PropTypes.string,
    linkText: PropTypes.string,
    obfuscate: PropTypes.bool,
    obfuscateChildren: PropTypes.bool,
    onClick: PropTypes.func,
    sms: PropTypes.string,
    style: PropTypes.shape( {} ),
    tel: PropTypes.string
}

export default Obfuscate
